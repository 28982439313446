// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from '@firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAh4XSKJFqruOniLqrsmROl4X1bfrPJqrU",
  authDomain: "soopa-60425.firebaseapp.com",
  projectId: "soopa-60425",
  storageBucket: "soopa-60425.appspot.com",
  messagingSenderId: "673911006912",
  appId: "1:673911006912:web:b200e0373a17b5fe45ef25",
  measurementId: "G-N489W49WW3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);