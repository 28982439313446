import React, { useRef } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import LandingTitle from '../assets/LandingTitle.png';
import FatShibaLine from '../assets/FatShibaLine.png';
import Momo from '../assets/Momo.png';

const Home = () => {
  // ===== Scramble Text =====
  
  const footerRef = useRef(null);
  
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='home flex flex-col w-screen h-auto text-white text-2xl text-center bg-soopablue bg-cover font-anton'>
      <Navbar />
      <div className='flex flex-col items-center mt-12'>
        <img src={LandingTitle} alt='' className='w-200 px-8'></img>
        <div className='buttons flex my-8'>
          <a
            href="https://tracker.soopafat.com"
            target="_blank"
            rel="noopener noreferrer"
            className='flex justify-center items-center border-2 rounded-3xl border-solid border-white py-1 px-8 bg-soopaorange text-4xl mr-4 hover:bg-[#FF905D]'
            data-tooltip-id="tracker"
            data-tooltip-content="Tracker"
          >
            <IoIcons.IoMdFitness className='mr-2'/>get FAT
          </a>
          <button
            onClick={scrollToFooter}
            className='border-2 rounded-3xl border-solid border-white py-1 px-8 text-4xl hover:bg-soopadarkblue'
          >
            learn more
          </button>
        </div>
      </div>
      <div className='flex items-center flex-col bg-soopablue w-full px-8'>
        <img src={FatShibaLine} alt='keyboardshibaleft' className='w-[700px] mt-4 aspect-[7/2]'></img>
        <h1 className="text-6xl mt-32 mb-6 text-center"><span className='text-soopapeach'>the </span>SOOPA FAT <span className='text-soopapeach'>experience </span></h1>
        <p className='font-sans font-light text-center'>ready to make a change?</p>
        <p className='font-sans font-light text-center'>for those who dare, SOOPA FAT is here.</p>
        <div className='flex mt-8'>
          <div className='py-8 px-4 border-fadedwhite border-4 border-solid rounded-3xl basis-1/2 m-2'>
            <h1 className='flex justify-center items-center font-sans font-light mb-1 border-b-2 border-fadedwhite'><FaIcons.FaBrain className='mr-2 p-1 bg-soopaorange rounded-2xl' />knowledge</h1>
            <p className='font-sans font-light italic text-lg'>science-backed diet and exercise info, no bs, effectiveness guaranteed</p>
          </div>
          <div className='py-8 px-4 border-fadedwhite border-4 border-solid rounded-3xl basis-1/2 m-2'>
            <h1 className='flex justify-center items-center font-sans font-light mb-1 border-b-2 border-fadedwhite'><FaIcons.FaHome className='mr-2 p-1 bg-soopaorange rounded-2xl' />community</h1>
            <p className='font-sans font-light italic text-lg'>some of the friendliest, non-toxic people on the planet, here to help and support</p>
          </div>
          <div className='py-8 px-4 border-fadedwhite border-4 border-solid rounded-3xl basis-1/2 m-2'>
            <h1 className='flex justify-center items-center font-sans font-light mb-1 border-b-2 border-fadedwhite'><IoIcons.IoIosApps className='mr-2 p-1 bg-soopaorange rounded-2xl' />tools</h1>
            <p className='font-sans font-light italic text-lg'>exclusive, custom tools for planning, executing, and tracking your progress</p>
          </div>
        </div>
      </div>
      <div className='flex items-center flex-col w-full px-8 mt-32'>
        <h1 className="text-6xl my-4 mb-8 text-center">about <span className='text-soopapeach'>us </span></h1>
        <div className="flex flex-col items-center px-48">
          <img src={Momo} alt='Momo' className='max-w-96 max-h-64 aspect-[3/2]'></img>
          <p className='font-sans font-light mt-4 px-8 text-center'>our chief woof officer, momo, runs the show here. she is aided by melon, a health enthusiast with too many degrees and too much passion to stay quiet.</p>
        </div>
      </div>
      <div ref={footerRef} className='mt-32'>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
