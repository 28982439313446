import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useLocation } from "react-router-dom";

import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io5";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";
import * as PiIcons from "react-icons/pi";
import Logo from '../assets/Logo.png';

// Discord Link is set to Never Expire, Unlimited Uses, Temporary Membership
const DISCORD_LINK = "https://discord.gg/499YaWkCWv"

const Navbar = () => {
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  
  const showSidebar = () => setSidebar(!sidebar);
  
  return (
    <div className="navbar flex justify-between items-center h-24 font-anton z-50 mt-3 px-4 text-4xl relative">
      <div className="navbar-left h-full flex justify-start items-center top-0 bottom-0 pt-1">
        <Link to="/" className="navbar-logo flex items-center">
          <img src={Logo} alt='inutech' className="w-16 hover:w-14 ease-in-out duration-300"></img>
        </Link>
      </div>
      <div className="navbar-right h-full flex justify-start items-center top-0 bottom-0 pt-1 ease-in-out duration-300">
        <FaIcons.FaBars onClick={showSidebar} className="drop-shadow-md hover:text-soopaorange ease-in-out duration-300 cursor-pointer mr-5" />
      </div>
      <div className={`nav-menu bg-soopaorange w-20 h-full fixed flex flex-col justify-between items-center top-0 pt-8 px-8 ${sidebar ? "right-0 duration-300" : "-right-full duration-300"}`}>
        <div className="nav-menu-top flex flex-col items-center">
          <Link
            to="#"
            className="nav-menu-item w-full p-4 ease-in-out duration-300 hover:text-soopablue"
            onClick={showSidebar}
          >
            <AiIcons.AiOutlineClose />
          </Link>
          <Link
            to="/"
            className="nav-menu-item w-full p-4 ease-in-out duration-300 hover:text-soopablue"
            style={{ color: location.pathname === "/" ? "soopadarkblue" : "" }}
            data-tooltip-id="home"
            data-tooltip-content="Home"
          >
            <IoIcons.IoHomeOutline />
          </Link>
          <a
            href="https://tracker.soopafat.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-menu-item w-full p-4 ease-in-out duration-300 hover:text-soopablue"
            style={{ color: location.pathname === "/" ? "soopadarkblue" : "" }}
            data-tooltip-id="tracker"
            data-tooltip-content="Tracker"
          >
            <PiIcons.PiBarbellLight />
          </a>
        </div>
        <div className="nav-menu-bottom flex flex-col items-center mb-8">
          <a
            href={DISCORD_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-menu-item w-full p-4 ease-in-out duration-300 hover:text-soopablue"
          >
            <RiIcons.RiDiscordLine />
          </a>
        </div>
        <Tooltip id="home" className='text-3xl'/>
        <Tooltip id="tracker" className='text-3xl'/>
      </div>
    </div>
  );
};

export default Navbar;
