import React, { useState } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as needed
import * as FaIcons from "react-icons/fa";
import Copyright from "./Copyright";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasReadTerms, setHasReadTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [error, setError] = useState({});

  const validateForm = (form) => {
    const error = {};
    if (!form.first_name.value.trim()) error.first_name = "Name is required.";
    if (!form.user_email.value.trim()) error.user_email = "Email is required.";
    if (!hasReadTerms) error.hasReadTerms = "You must be over 18 years of age.";
    return error;
  };

  const saveToFirestore = async (e) => {
    e.preventDefault();
    const formError = validateForm(e.target);
    if (Object.keys(formError).length > 0) {
      setError(formError);
      return;
    }

    setError({});
    setIsSubmitting(true);
    
    try {
      await addDoc(collection(db, 'emailList'), {
        name: e.target.first_name.value.trim(),
        email: e.target.user_email.value.trim(),
      });
      setSnackbarMessage('Success! You have been added to our mailing list. Stay tuned!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error saving to Firestore:', error);
      setSnackbarMessage('Something went wrong! Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setOpen(true); // Show the alert
      setIsSubmitting(false);
      e.target.reset(); // Clears the form after saving the data
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  return (
    <div className="footer bg-soopaorange bg-cover text-white text-start bottom-0 flex flex-col px-8">
      <div className="footer-content flex items-start justify-between pb-4 w-full">
        <div className="pt-8 sm:text-4xl w-full">
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>get notified when SOOPA FAT goes live</span>
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>early access to articles, videos and apps</span>
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>exclusive rates for early members</span>
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>no spam, we promise</span>
          <form onSubmit={saveToFirestore} className="flex flex-col text-xl mt-8 font-sans font-light">
            <input type="text" name="first_name" className="text-black p-1 mb-1 w-full" placeholder="name*" />
            {error.first_name && <p className="text-red-500 text-sm">{error.first_name}</p>}
            <input type="email" name="user_email" className="text-black p-1 mb-1 w-full" placeholder="email*" />
            {error.user_email && <p className="text-red-500 text-sm">{error.user_email}</p>}
            <div className="flex items-center justify-between mb-4">
              <div>
                <input 
                  type="checkbox" 
                  checked={hasReadTerms} 
                  onChange={() => setHasReadTerms(!hasReadTerms)} 
                  className="mr-2"
                />
                <label onClick={() => setHasReadTerms(!hasReadTerms)}>i am over 18 years old</label>
              </div>
              <input 
                type="submit" 
                value="sign up" 
                className="mt-4 border-white border-2 rounded-3xl border-white py-1 px-4 text-white hover:bg-soopablue hover:cursor-pointer" 
                disabled={isSubmitting} 
              />
            </div>
            {error.hasReadTerms && <p className="text-red-500 text-sm">{error.hasReadTerms}</p>}
          </form>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Copyright sx={{ mt: 8, mb: 4 }} color="white"/>
    </div>
  );
};

export default Footer;
